import React, { Component, Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import apis from '../../../services/apis'

import OhifLink from '../../Viewers/OhifLink'
import StoneLink from '../../Viewers/StoneLink'
import Modal from 'react-bootstrap/Modal'
import Metadata from '../../Metadata/Metadata'
import Modify from '../../Modify/Modify'
import { toast } from 'react-toastify'
import CreateDicom from '../../CreateDicom/CreateDicom'
import { CreateReportButton } from '../../CreateReport/CreateReportButton'
import { ShareButton } from '../../Share/ShareButton'
import { CardShareButton } from '../../CardShare/CardShareButton'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
//this file is created by rishabh 11/05/22 IST @coderrishabhdubey@gmail.com

class ActionBoutonView extends Component {

    state = {
        showMetadata: false,
    }

    static defaultProps = {
        hiddenMetadata: true,
        hiddenCreateDicom: false
    }

    setMetadata = () => {
        this.setState({
            showMetadata: !this.state.showMetadata
        })
    }



    handleClick = (e) => {
        e.stopPropagation()
    }


    render = () => {
        return (
            <Fragment>
                {/*modal pour metadata*/}
                <Modal show={this.state.showMetadata} onHide={this.setMetadata} scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Metadata</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Metadata serieID={this.props.orthancID} />
                    </Modal.Body>
                </Modal>

                <Dropdown onClick={this.handleClick} drop='left' className="text-center">
                    <Dropdown.Toggle style={{ background: '#4CBCD2' }} variant="button-dropdown btn otjs-button otjs-button-blue" id="dropdown-basic" className="button-dropdown button-dropdown-green">
                        View
                    </Dropdown.Toggle>
                    {/* tukar viewer */}
                    <Dropdown.Menu className="mt-2 border border-dark border-2">
                        
                        {/* <a style={{ textDecoration: 'none' }} href={this.props.radiant} target='_blank' >
                            <button type='button' className='dropdown-item '
                            >RADIANT  
                            </button>
                        </a>

                        <a style={{ textDecoration: 'none' }} href={this.props.osirix} target='_blank'>
                            <button className='dropdown-item ' type='button' hidden={this.props.hiddenDelete}
                            >
                                HOROS
                            </button>
                        </a> */}



                        <a style={{ textDecoration: 'none' }} href={this.props.osimis_link} target='_blank'>
                            <button className='dropdown-item ' type='button' hidden={this.props.hiddenDelete}
                            >
                                OSIMIS Viewer
                            </button>
                        </a>

                        {/* <a style={{ textDecoration: 'none' }} href={this.props.wsi_link} target='_blank'>
                            <button className='dropdown-item ' type='button' hidden={this.props.hiddenDelete}
                            >
                                WSI Viewer
                            </button>
                        </a> */}
                        
                        <a style={{ textDecoration: 'none' }} href={this.props.downloadzip} target='_blank'>
                            <button className='dropdown-item ' type='button' hidden={this.props.hiddenDelete}
                            >

                                Download ZIP
                            </button>
                        </a>
                        
                        

                        <Link style={{ textDecoration: 'none' }} to={{ pathname: this.props.OhifLink }} target='_blank' >
                            <button className='dropdown-item ' type='button' hidden={this.props.hiddenDelete}
                            >
                                A.I. Viewer
                            </button>
                        </Link>
                    </Dropdown.Menu>
                </Dropdown>
            </Fragment>
        )
    }
}


export default ActionBoutonView;
